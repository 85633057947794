<template>
  <div>

  </div>
  
</template>

<script>

export default {
  name: "home",
  
};
</script>
<style scoped></style>
